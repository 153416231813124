import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
export default async function ({ store, params, route, redirect, env, $auth }) {
  const gamesName = JSON.parse(
    JSON.stringify(store.state.sportsbookCategory.data)
  )
  if (isEmpty(gamesName)) {
    await store.dispatch('sportsbookCategory/get')
  }
  const gameAvailable = find(
    store.state.sportsbookCategory.data,
    (singleGame) => {
      if (params.category) {
        if (
          params.category === 'horse-racing' ||
          params.category === 'greyhound-racing'
        ) {
          return singleGame
        } else {
          return singleGame.name === params.category
        }
      }
    }
  )
  if (isEmpty(gameAvailable)) {
    return redirect('/')
  }
}
