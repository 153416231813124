export const state = () => ({
  data: {},
  loading: false,
  userId: '',
  sessionId: '',
  sessionName: '',
  betType: '',
  modalOpen: false,
  ladders: '',
  error: {}
})
export const getters = {
  modalOpen: (state) => state.modalOpen
}
export const mutations = {
  set_modal(state, data) {
    state.modalOpen = data
  },
  set_data(state, data) {
    state.userId = this.$auth.user._id
    state.ladders = data
  },
  set_sessionName(state, sessionName) {
    state.sessionName = sessionName
  },
  set_betType(state, betType) {
    state.betType = betType
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getFancyLadder({ commit }, session) {
    commit('set_data', {})
    commit('set_loading', true)
    try {
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_V2_API_URL,
        url: 'book/getLadder',
        data: {
          userId: this.$auth.user._id,
          sessionId: session.session.sessionId,
          type: session.session.level
        }
      })
      commit('set_data', data.data)
      commit('set_sessionName', session.session.sessionName)
      commit('set_betType', session.session.level)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getPremiumFancyLadder({ commit }, session) {
    commit('set_data', {})
    commit('set_loading', true)
    try {
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.PREMIUM_BOOKMAKER_URL,
        url: 'Book/getLadder',
        data: {
          userId: this.$auth.user._id,
          marketId: session.session.sessionId,
          type: session.session.level
        }
      })
      commit('set_data', data.data)
      commit('set_sessionName', session.session.sessionName)
      commit('set_betType', session.session.level)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
