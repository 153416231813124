import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64a36327 = () => interopDefault(import('../pages/aml-policies.vue' /* webpackChunkName: "pages/aml-policies" */))
const _025a6428 = () => interopDefault(import('../pages/bets.vue' /* webpackChunkName: "pages/bets" */))
const _2ed52922 = () => interopDefault(import('../pages/casino/index.vue' /* webpackChunkName: "pages/casino/index" */))
const _898fbfec = () => interopDefault(import('../pages/casino-slots/index.vue' /* webpackChunkName: "pages/casino-slots/index" */))
const _0012cdc4 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _489df0f4 = () => interopDefault(import('../pages/change-password-kyc.vue' /* webpackChunkName: "pages/change-password-kyc" */))
const _1a889f55 = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _8c6d3346 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _772588e2 = () => interopDefault(import('../pages/finsa/index.vue' /* webpackChunkName: "pages/finsa/index" */))
const _0417bc8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _313c5ac2 = () => interopDefault(import('../pages/google-auth.vue' /* webpackChunkName: "pages/google-auth" */))
const _81fbb47a = () => interopDefault(import('../pages/inplay.vue' /* webpackChunkName: "pages/inplay" */))
const _5ee13f65 = () => interopDefault(import('../pages/line-market-rules.vue' /* webpackChunkName: "pages/line-market-rules" */))
const _08ed19a2 = () => interopDefault(import('../pages/live-cards/index.vue' /* webpackChunkName: "pages/live-cards/index" */))
const _db8fe302 = () => interopDefault(import('../pages/live-casino/index.vue' /* webpackChunkName: "pages/live-casino/index" */))
const _7bccbde6 = () => interopDefault(import('../pages/live-games/index.vue' /* webpackChunkName: "pages/live-games/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0e93e29d = () => interopDefault(import('../pages/login_2FA.vue' /* webpackChunkName: "pages/login_2FA" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _09484100 = () => interopDefault(import('../pages/my-markets.vue' /* webpackChunkName: "pages/my-markets" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _367ad087 = () => interopDefault(import('../pages/premium-bookmaker-fancy.vue' /* webpackChunkName: "pages/premium-bookmaker-fancy" */))
const _3fb959aa = () => interopDefault(import('../pages/premium-cricket-rules.vue' /* webpackChunkName: "pages/premium-cricket-rules" */))
const _0f0c8f0b = () => interopDefault(import('../pages/premium-sportsbook/index.vue' /* webpackChunkName: "pages/premium-sportsbook/index" */))
const _4a159b1c = () => interopDefault(import('../pages/premium-sportsbook-rules.vue' /* webpackChunkName: "pages/premium-sportsbook-rules" */))
const _d79f07d6 = () => interopDefault(import('../pages/profit-loss/index.vue' /* webpackChunkName: "pages/profit-loss/index" */))
const _6bb801a8 = () => interopDefault(import('../pages/profit-loss-new/index.vue' /* webpackChunkName: "pages/profit-loss-new/index" */))
const _1bb7fb23 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _7641eb8e = () => interopDefault(import('../pages/rules-regulations.vue' /* webpackChunkName: "pages/rules-regulations" */))
const _137800ab = () => interopDefault(import('../pages/rummy-poker/index.vue' /* webpackChunkName: "pages/rummy-poker/index" */))
const _3543fae6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _cb830956 = () => interopDefault(import('../pages/sportsbook/index.vue' /* webpackChunkName: "pages/sportsbook/index" */))
const _2bb45229 = () => interopDefault(import('../pages/time-settings.vue' /* webpackChunkName: "pages/time-settings" */))
const _31a039e8 = () => interopDefault(import('../pages/transfer-statements.vue' /* webpackChunkName: "pages/transfer-statements" */))
const _0de345df = () => interopDefault(import('../pages/transfer-statements-new.vue' /* webpackChunkName: "pages/transfer-statements-new" */))
const _17eff8bc = () => interopDefault(import('../pages/wac/index.vue' /* webpackChunkName: "pages/wac/index" */))
const _3008b5c9 = () => interopDefault(import('../pages/casino/casino-list/index.vue' /* webpackChunkName: "pages/casino/casino-list/index" */))
const _c8a2b8de = () => interopDefault(import('../pages/casino/games.vue' /* webpackChunkName: "pages/casino/games" */))
const _f42b7b6e = () => interopDefault(import('../pages/casino/live-casino/index.vue' /* webpackChunkName: "pages/casino/live-casino/index" */))
const _006ade86 = () => interopDefault(import('../pages/live-casino-old/live-casino.vue' /* webpackChunkName: "pages/live-casino-old/live-casino" */))
const _b8c97a2a = () => interopDefault(import('../pages/profit-loss-new/detail.vue' /* webpackChunkName: "pages/profit-loss-new/detail" */))
const _eb9bd3c4 = () => interopDefault(import('../pages/profit-loss/detail.vue' /* webpackChunkName: "pages/profit-loss/detail" */))
const _5b437629 = () => interopDefault(import('../pages/casino/casino-list/index-copy.vue' /* webpackChunkName: "pages/casino/casino-list/index-copy" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _252a9fe8 = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/index" */))
const _5014e4f7 = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index" */))
const _3cfac61c = () => interopDefault(import('../pages/casino-slots/_id.vue' /* webpackChunkName: "pages/casino-slots/_id" */))
const _23e87557 = () => interopDefault(import('../pages/live-cards/_id.vue' /* webpackChunkName: "pages/live-cards/_id" */))
const _1e7ab79a = () => interopDefault(import('../pages/login/_id.vue' /* webpackChunkName: "pages/login/_id" */))
const _6270973a = () => interopDefault(import('../pages/sportsbook/_category/index.vue' /* webpackChunkName: "pages/sportsbook/_category/index" */))
const _13a6868a = () => interopDefault(import('../pages/wac/_id.vue' /* webpackChunkName: "pages/wac/_id" */))
const _11e269e2 = () => interopDefault(import('../pages/live-casino-old/_category/_id.vue' /* webpackChunkName: "pages/live-casino-old/_category/_id" */))
const _c5bd6084 = () => interopDefault(import('../pages/live-casino/_system/_pageNo/index.vue' /* webpackChunkName: "pages/live-casino/_system/_pageNo/index" */))
const _dd1256be = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/index.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/index" */))
const _1a96c6ae = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/_id/_betradar.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/_id/_betradar" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aml-policies",
    component: _64a36327,
    name: "aml-policies"
  }, {
    path: "/bets",
    component: _025a6428,
    name: "bets"
  }, {
    path: "/casino",
    component: _2ed52922,
    name: "casino"
  }, {
    path: "/casino-slots",
    component: _898fbfec,
    name: "casino-slots"
  }, {
    path: "/change-password",
    component: _0012cdc4,
    name: "change-password"
  }, {
    path: "/change-password-kyc",
    component: _489df0f4,
    name: "change-password-kyc"
  }, {
    path: "/changePassword",
    component: _1a889f55,
    name: "changePassword"
  }, {
    path: "/favorites",
    component: _8c6d3346,
    name: "favorites"
  }, {
    path: "/finsa",
    component: _772588e2,
    name: "finsa"
  }, {
    path: "/forgot-password",
    component: _0417bc8b,
    name: "forgot-password"
  }, {
    path: "/google-auth",
    component: _313c5ac2,
    name: "google-auth"
  }, {
    path: "/inplay",
    component: _81fbb47a,
    name: "inplay"
  }, {
    path: "/line-market-rules",
    component: _5ee13f65,
    name: "line-market-rules"
  }, {
    path: "/live-cards",
    component: _08ed19a2,
    name: "live-cards"
  }, {
    path: "/live-casino",
    component: _db8fe302,
    name: "live-casino"
  }, {
    path: "/live-games",
    component: _7bccbde6,
    name: "live-games"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/login_2FA",
    component: _0e93e29d,
    name: "login_2FA"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance"
  }, {
    path: "/my-markets",
    component: _09484100,
    name: "my-markets"
  }, {
    path: "/news",
    component: _3b011b86,
    name: "news"
  }, {
    path: "/premium-bookmaker-fancy",
    component: _367ad087,
    name: "premium-bookmaker-fancy"
  }, {
    path: "/premium-cricket-rules",
    component: _3fb959aa,
    name: "premium-cricket-rules"
  }, {
    path: "/premium-sportsbook",
    component: _0f0c8f0b,
    name: "premium-sportsbook"
  }, {
    path: "/premium-sportsbook-rules",
    component: _4a159b1c,
    name: "premium-sportsbook-rules"
  }, {
    path: "/profit-loss",
    component: _d79f07d6,
    name: "profit-loss"
  }, {
    path: "/profit-loss-new",
    component: _6bb801a8,
    name: "profit-loss-new"
  }, {
    path: "/registration",
    component: _1bb7fb23,
    name: "registration"
  }, {
    path: "/rules-regulations",
    component: _7641eb8e,
    name: "rules-regulations"
  }, {
    path: "/rummy-poker",
    component: _137800ab,
    name: "rummy-poker"
  }, {
    path: "/settings",
    component: _3543fae6,
    name: "settings"
  }, {
    path: "/sportsbook",
    component: _cb830956,
    name: "sportsbook"
  }, {
    path: "/time-settings",
    component: _2bb45229,
    name: "time-settings"
  }, {
    path: "/transfer-statements",
    component: _31a039e8,
    name: "transfer-statements"
  }, {
    path: "/transfer-statements-new",
    component: _0de345df,
    name: "transfer-statements-new"
  }, {
    path: "/wac",
    component: _17eff8bc,
    name: "wac"
  }, {
    path: "/casino/casino-list",
    component: _3008b5c9,
    name: "casino-casino-list"
  }, {
    path: "/casino/games",
    component: _c8a2b8de,
    name: "casino-games"
  }, {
    path: "/casino/live-casino",
    component: _f42b7b6e,
    name: "casino-live-casino"
  }, {
    path: "/live-casino-old/live-casino",
    component: _006ade86,
    name: "live-casino-old-live-casino"
  }, {
    path: "/profit-loss-new/detail",
    component: _b8c97a2a,
    name: "profit-loss-new-detail"
  }, {
    path: "/profit-loss/detail",
    component: _eb9bd3c4,
    name: "profit-loss-detail"
  }, {
    path: "/casino/casino-list/index-copy",
    component: _5b437629,
    name: "casino-casino-list-index-copy"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType",
    component: _252a9fe8,
    name: "casino-casino-list-provider-gameType-subGameType"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType?/:id",
    component: _5014e4f7,
    name: "casino-casino-list-provider-gameType-subGameType-id"
  }, {
    path: "/casino-slots/:id",
    component: _3cfac61c,
    name: "casino-slots-id"
  }, {
    path: "/live-cards/:id?",
    component: _23e87557,
    name: "live-cards-id"
  }, {
    path: "/login/:id",
    component: _1e7ab79a,
    name: "login-id"
  }, {
    path: "/sportsbook/:category",
    component: _6270973a,
    name: "sportsbook-category"
  }, {
    path: "/wac/:id",
    component: _13a6868a,
    name: "wac-id"
  }, {
    path: "/live-casino-old/:category?/:id",
    component: _11e269e2,
    name: "live-casino-old-category-id"
  }, {
    path: "/live-casino/:system?/:pageNo",
    component: _c5bd6084,
    name: "live-casino-system-pageNo"
  }, {
    path: "/sportsbook/:category/:subCategory",
    component: _dd1256be,
    name: "sportsbook-category-subCategory"
  }, {
    path: "/sportsbook/:category/:subCategory/:id/:betradar?",
    component: _1a96c6ae,
    name: "sportsbook-category-subCategory-id-betradar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
