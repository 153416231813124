export default {
  playTechFbPixelCode() {
    if (
      process.env &&
      process.env.APP_TITLE &&
      process.env.APP_TITLE === 'Play Exchange'
    ) {
      const playTechFb = document.createElement('script')
      playTechFb.setAttribute('type', 'text/javascript')

      playTechFb.innerHTML = `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '5258934704154446');
        fbq('track', 'PageView')`
      document.head.appendChild(playTechFb)
      const noscript = document.createElement('noscript')
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=5258934704154446&ev=PageView&noscript=1"
      />`
      document.head.appendChild(noscript)
    } else if (
      process.env &&
      process.env.APP_TITLE &&
      process.env.APP_TITLE === 'Matrix Exchange'
    ) {
      const MatrixFB = document.createElement('script')
      MatrixFB.setAttribute('type', 'text/javascript')

      MatrixFB.innerHTML = `!function (f, b, e, v, n, t, s) {
              if (f.fbq) return; n = f.fbq = function () {
                  n.callMethod ?
                      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
              };
              if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
              n.queue = []; t = b.createElement(e); t.async = !0;
              t.src = v; s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s)
          }(window, document, 'script',
              'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1104516606824898');
          fbq('track', 'PageView')`
      document.head.appendChild(MatrixFB)
      const noscript = document.createElement('noscript')
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=1104516606824898&ev=PageView&noscript=1"
      />`
      document.head.appendChild(noscript)
    } else if (
      process.env &&
      process.env.APP_TITLE &&
      process.env.APP_TITLE === '77 Exchange'
    ) {
      const ExchangeFB = document.createElement('script')
      ExchangeFB.setAttribute('type', 'text/javascript')

      ExchangeFB.innerHTML = `!(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )
      fbq('init', '2490482874461921')
      fbq('track', 'PageView')`
      document.head.appendChild(ExchangeFB)
      const noscript = document.createElement('noscript')
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2490482874461921&ev=PageView&noscript=1"
      />`
      document.head.appendChild(noscript)
    } else if (
      process.env &&
      process.env.APP_TITLE &&
      process.env.APP_TITLE === 'Deal999 Exchange'
    ) {
      const DealExchangeFB = document.createElement('script')
      DealExchangeFB.setAttribute('type', 'text/javascript')

      DealExchangeFB.innerHTML = `!(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )
      fbq('init', '536872625327767')
      fbq('track', 'PageView')`
      document.head.appendChild(DealExchangeFB)
      const noscript = document.createElement('noscript')
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=536872625327767&ev=PageView&noscript=1"
      />`
      document.head.appendChild(noscript)
    }
  }
  // clarity() {
  //   let clarityId = ''
  //   if (process.client && process.env && process.env.CLARITY_CODE) {
  //     clarityId = process.env.CLARITY_CODE
  //     const claritycheck = document.createElement('script')
  //     claritycheck.innerHTML =
  //       `(function (c, l, a, r, i, t, y) {
  //               c[a] =
  //                   c[a] ||
  //                   function () {
  //                       ;(c[a].q = c[a].q || []).push(arguments)
  //                   }
  //               t = l.createElement(r)
  //               t.async = 1
  //               t.src = "https://www.clarity.ms/tag/" + i
  //               y = l.getElementsByTagName(r)[0]
  //               y.parentNode.insertBefore(t, y)
  //           })(window, document, "clarity", "script", "` +
  //       clarityId +
  //       `")`
  //     document.head.appendChild(claritycheck)
  //   }
  // }
}
