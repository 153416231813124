export const state = () => ({
  userBalanceData: {},
  netExposureData: {},
  loading: false,
  error: {},
  modalShow: false
})

export const mutations = {
  set_data(state, data) {
    if (data.value) {
      state.userBalanceData = data.data
    } else {
      state.userBalanceData = {}
    }
  },
  set_data_netexposure(state, data) {
    if (data.value) {
      state.netExposureData = data.data
    } else {
      state.netExposureData = {}
    }
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  sessionModal_open(state, data) {
    state.modalShow = true
  },
  sessionModal_close(state) {
    state.modalShow = false
  }
}

export const actions = {
  async getCurrentBalance({ commit }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'sportsbook/getCurrentBalanceForSportsBook',
        data: {
          // _accessToken: this.$auth.getToken('customLocal')
        }
      })
      commit('set_data', response.data)
      commit('set_data_netexposure', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async saveUser({ commit }, { deviceID, errorLogs }) {
    console.log('deviceID', errorLogs)
    let deviceData = {}
    if (errorLogs) {
      deviceData = errorLogs
    } else {
      deviceData = { deviceId: deviceID }
    }
    try {
      commit('set_loading', true)
      await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: '/Memberdevice/saveDeviceId',
        data: deviceData
      })
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
