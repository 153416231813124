export const state = () => ({
  inplaymarkets: [],
  upcomingMarkets: [],
  loading: false,
  error: {}
})

export const mutations = {
  set_markets(state, data) {
    if (data) {
      const matchIds = []
      const eventIds = []

      data.forEach((e) => {
        if (e.markets && e.markets.length > 0) {
          e.markets.forEach((market) => {
            if (
              market &&
              market.markets &&
              market.markets.length > 0 &&
              market.markets[0].inPlayStatus === 'Open'
            ) {
              market.eventId && eventIds.push(market.eventId)
            }
            if (
              market.inPlayStatus === 'Open' &&
              market.betfairStatus !== 'SUSPENDED'
            ) {
              market.betfairId && matchIds.push(market.betfairId)
            }
          })
        }
      })
      if (matchIds.length > 0) {
        this.commit('socketInplayRate/SET_IDS', matchIds)
        this.commit('socketBookmaker/SET_IDS', matchIds)
      }
      if (eventIds.length > 0) {
        const joinRoomName = eventIds.map((eventId) => {
          return `uof_main_market_${eventId}`
        })
        this.commit('socketInplayRate/SET_EVENT_IDS', joinRoomName)
      }
      if (this.$auth.loggedIn && this.$auth.user) {
        if (!this.$auth.user.horseRacing) {
          data = data.filter((game) => game.name !== 'Horse Racing')
        }
        if (!this.$auth.user.greyhoundRacing) {
          data = data.filter((game) => game.name !== 'Greyhound Racing')
        }
      }
      state.inplaymarkets = data
    }
  },
  set_upcoming_markets(state, data) {
    if (data) {
      const matchIds = []
      const eventIds = []

      data.forEach((e) => {
        if (e.markets && e.markets.length > 0) {
          e.markets.forEach((market) => {
            market.betfairId && matchIds.push(market.betfairId)
            market.eventId && eventIds.push(market.eventId)
          })
        }
      })
      if (matchIds.length > 0) {
        this.commit('socketRate/SET_IDS', matchIds)
        this.commit('socketBookmaker/SET_IDS', matchIds)
      }
      if (eventIds.length > 0) {
        const joinRoomName = eventIds.map((eventId) => {
          return `uof_main_market_${eventId}`
        })
        this.commit('socketRate/SET_EVENT_IDS', joinRoomName)
      }
      if (this.$auth.loggedIn && this.$auth.user) {
        if (!this.$auth.user.horseRacing) {
          data = data.filter((game) => game.name !== 'Horse Racing')
        }
        if (!this.$auth.user.greyhoundRacing) {
          data = data.filter((game) => game.name !== 'Greyhound Racing')
        }
      }
      state.upcomingMarkets = data
    }
  },
  set_empty_markets(state) {
    state.upcomingMarkets = []
    state.markets = []
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}
export const actions = {
  async get(
    { commit },
    { userId, socketData, upComing = false, loading = true }
  ) {
    // console.log('get inplay', socketData)
    try {
      commit('set_loading', loading)
      commit('set_empty_markets')
      if (!socketData) {
        const { data } = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_V2_API_URL,
          url: 'category/getInPlayMarkets',
          data: {
            _id: userId,
            _accessToken: this.$auth.getToken('customLocal'),
            upComing
          }
        })
        if (upComing) {
          commit('set_upcoming_markets', data.data)
        } else {
          commit('set_markets', data.data)
        }
      } else if (socketData && upComing) {
        // console.log('set_upcoming_markets ............', socketData)
        commit('set_upcoming_markets', socketData)
      } else {
        // console.log('set_markets ............', socketData)
        commit('set_markets', socketData)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
