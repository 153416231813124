import socket from '@/plugins/socket.client'
export const state = () => ({
  socketId: undefined
})

export const mutations = {
  RESET_SOCKET_ID(state) {
    state.socketId = undefined
  },
  SET_SOCKET_ID(state, data) {
    state.socketId = data
  }
}

export const actions = {
  joinRooms({ commit, state }, { socketId, marketId, gameName }) {
    // console.log(socketId, marketId)
    commit('SET_SOCKET_ID', socketId)
    if (marketId) {
      try {
        socket.commonSocket.emit('joinRooms', {
          roomNames: [
            `odds_change_${
              this.$router.currentRoute.params.subCategory !== 'UOFGame'
            }_${marketId}`
          ]
        })
        socket.commonSocket.emit('joinRooms', {
          roomNames: ['uof_refresh_' + gameName]
        })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  joinRoomsNonBetFairEnable({ commit, state }, { socketId, eventId }) {
    commit('SET_SOCKET_ID', socketId)
    if (eventId) {
      try {
        socket.commonSocket.emit('joinRooms', {
          roomNames: [`uof_enable_${eventId}`]
        })
        socket.commonSocket.emit('joinRooms', {
          roomNames: [`uof_main_market_${eventId}`]
        })
      } catch (error) {
        const errorData = {}
        errorData.roomNames = state.ids
        this.commit('socketMain/socketFailureLog', error, errorData)
      }
    }
  },
  leaveRooms({ state, commit }, { marketId, gameName, gameType }) {
    try {
      if (marketId) {
        socket.commonSocket.emit('leaveRooms', {
          roomNames: [`odds_change_${gameType !== 'UOFGame'}_${marketId}`]
        })
        socket.commonSocket.emit('leaveRooms', {
          roomNames: ['uof_refresh_' + gameName]
        })
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = marketId
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  },
  leaveRoomsNonBetFairEnable({ state, commit }, { eventId }) {
    // console.log(marketId)
    try {
      if (eventId) {
        socket.commonSocket.emit('leaveRooms', {
          roomNames: [`uof_enable_${eventId}`]
        })
        socket.commonSocket.emit('leaveRooms', {
          roomNames: [`uof_main_market_${eventId}`]
        })
      }
    } catch (error) {
      const errorData = {}
      errorData.roomNames = eventId
      this.commit('socketMain/socketFailureLog', error, errorData)
    }
  }
}
